import React from 'react';
import BreakoutBox from '../components/BreakoutBox';
import PageHeader from '../components/PageHeader';
import { SvgHistory, SvgMap2, SvgAccommodation, SvgOther } from '../components/svg';
// import Map from "../components/Map";

export default function NlAbout() {

  return (
    <div className="nl-about-page">
      <PageHeader title="About" />

      <p className="page-callout">Australian folk-roots music festival in the
        historic country town of Newstead, near Castlemaine, Central
        Victoria.</p>

      <BreakoutBox
        svg={SvgHistory}
        title="About Us"
        contents={
          <>
            <p>Newstead Live Music Festival has been running for over twenty
              years, the first six years as Chewton Folk Festival.</p>
            <p>The festival takes place over the last weekend in January, when a
              range of diverse and highly regarded international and national
              artists perform in live music venues, concert spaces, workshops,
              impromptu sessions, spoken word performances and open-mic
              sessions. Music for all ages and a range of genres.</p>
            <p>Newstead Live is managed by a voluntary body of members working
              together to develop their community.</p>
          </>
        }
      />

      <BreakoutBox
        svg={SvgMap2}
        title="Getting There"
        contents={
          <>
            <p>Newstead is a thriving, vibrant town on the Loddon River in
              Central Victoria, 15 minutes from Castlemaine and about 1.5 hours
              north-west of
              Melbourne.</p>{/*<p><a href="https://www.ptv.vic.gov.au/stop/view/20301/" target="_blank" rel='noreferrer'>Details on trains to Castlemaine</a></p>*/}
            <p>
              <a href="https://www.ptv.vic.gov.au/stop/4317/panmure-stpyrenees-hwy/3/regional-train/" target="_blank" rel="noreferrer">Details
                on public transport to Castlemaine</a>
            </p>{/*<p><a href="http://newstead.vic.au/service/public-transport" target="_blank" rel='noreferrer'>Details on public transport to Newstead</a></p>*/}
            <p>
              {/*<a href="https://goo.gl/maps/FD9pzhtgfr3w2Zbg6" target="_blank" rel="noreferrer">Get driving directions</a>*/}
              <a href="https://www.google.com/maps/dir//Newstead+Victoria+3462/@-37.1063319,144.0623377,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x6ad0d302cd4a21c3:0x40579a430a081e0" target="_blank" rel="noreferrer">Get
                driving directions</a>
            </p>
            <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12727.56031253803!2d144.062534!3d-37.107734!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad0d302cd4a21c3%3A0x40579a430a081e0!2sNewstead%20VIC%203462!5e0!3m2!1sen!2sau!4v1737448669881!5m2!1sen!2sau" style={{ width: '100%', height: '350px', border: '0' }} loading="lazy"></iframe>
            </div>
            {/*
            <div>
              <Map />
            </div>
*/}
          </>
        }
      />
      {
      //*
      <BreakoutBox
        svg={SvgAccommodation}
        title="Accommodation"
        contents={
          <>
            <p className='action-button'><a href="https://events.humanitix.com/newstead-live-music-festival-2025" target="_blank" rel='noreferrer'>BOOK NOW</a></p>

            <h4>Camping</h4>

            <p>There are two campgrounds available during the festival:</p>

            <h5>The Oval</h5>
            <div>
              <p>Located at the Recreation Reserve, where The Troubadour is based. There are basic facilities and there is no electricity. There are only a limited number of sites available. You must book and pay before the start of the festival. Cost is $80 for a double for the weekend.</p>
              <p>Camping is on the playing surface of the oval and on the perimeter.</p>
              <p>Restrictions on the playing surface are single axel vehicles only and no metal tent pegs allowed. Plastic tent pegs only. Once you have set up your camp site, you can move your car off the playing surface if you plan to use it during the festival. You will not be allowed to drive in and out of the oval once you are set up. Adverse weather conditions may prohibit camping on the playing surface.</p>
            </div>

            <h5>The Racecourse Reserve</h5>
            <div>
              <p>This is a little way out of town. There are shower and toilet facilities. There is no electricity.</p>
              <p>Cost is $10 per night per couple, then $5 for each additional adult.</p>
              <p>There is a shuttle bus that runs between the campground and the venues in the township. Pay on entry to the reserve.</p>
              <p>NOTE: YOU WILL NEED CASH FOR THIS!</p>
            </div>

            <h4>Other Accommodation</h4>
            <p><a
              href="https://www.bendigoregion.com.au/visit-castlemaine-maldon/stay-in-castlemaine-maldon-surrounds"
              target="_blank" rel="noreferrer noopener">Click here</a> for other accommodation options in the area.
            </p>
          </>
        }
      />
      //*/
      }
      {
        //*
        <BreakoutBox
          svg={SvgOther}
          title='Other information'
          contents={
            <div>
              <h4>Food &amp; Drink</h4>
              <p>There are numerous options available in town during the festival; The Crown Hotel, Dig Cafe, IGA Local Supermarket &amp; Bakery, The Country Store (eat in or take away), The Troubadour Wine Bar, Thai Cafe &amp; Continental Breakfast at the Community Centre, and food vans in the Festival Village</p>

              <h4>Shuttle Bus</h4>
              <p>Shuttle bus runs between the campground and the venues in the township. You can buy a weekend pass for $5 or pay a gold coin donation per trip.</p>

              <h4>ATM</h4>
              <p>There is <i>no ATM in Newstead</i>. All businesses in our small town take cards but please note small businesses do not have the capacity to give cash out. Please bring cash if you need it.</p>

              <h4>What to Bring</h4>
              <p>A sense of humour, your dancing shoes, togs, towel, swag, musical instrument and a bottle of Buckfast. The rest will look after itself.</p>
            </div>
          }
        />
        //*/
      }
    </div>
  );

}