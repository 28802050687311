import {useEffect, useState, React} from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";

// app components
import NlHome from "./routes/nlHome";
import NlPerformers from "./routes/nlPerformers";
import NlPerformerPage, {performerLoader} from "./routes/nlPerformerPage";
import NlVenues from "./routes/nlVenues";
import ErrorPage from "./ErrorPage";
import NlVenuePage, {venueLoader} from "./routes/nlVenuePage";
import NlFaves from "./routes/nlFaves";
import NlSchedule from "./routes/nlSchedule";

// stylesheets
import './index.scss';

// data import
import performers from './json/performers.json';
import venues from './json/venues.json';
import schedule from './json/schedule.json';

// others
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import NlAbout from "./routes/nlAbout";
import NlFaq from "./routes/nlFaq";
import {Image, Nav, Navbar} from "react-bootstrap";

export function nlGetData() {
  // already sorted now: performers.sort((a, b) => a.title.localeCompare(b.title));
  return {
    'performers': performers,
    'venues': venues,
    'schedule': schedule,
  };
}

export async function dataLoader() {
  let nlData = nlGetData();
  return { nlData };
}

// Routes
const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    loader: dataLoader,
    children: [
      {
        path: "/",
        element: <NlHome />,
        loader: dataLoader,
      },
      {
        path: "/performers",
        element: <NlPerformers />,
        loader: dataLoader,
      },
      {
        path: "/performer/:slug",
        element: <NlPerformerPage />,
        loader: performerLoader,
      },
      {
        path: "/venues",
        element: <NlVenues />,
        loader: dataLoader,
      },
      {
        path: "/venue/:slug",
        element: <NlVenuePage />,
        loader: venueLoader,
      },
      {
        path: "/favourites",
        element: <NlFaves />,
        loader: dataLoader,
      },
      {
        path: "/schedule",
        element: <NlSchedule />,
        loader: dataLoader,
      },
      {
        path: "/about",
        element: <NlAbout />
      },
      {
        path: "/faq",
        element: <NlFaq />
      },
    ],
  },
]);

let deferredPrompt;

// Main app
export default function App() {

  const [pwa, setPwa] = useState({ installable: false, isIos: false, modal: false, checked: false });

  const lsKey = 'nl24-pwa-dismissed';
  const isDismissed = () => localStorage.getItem(lsKey);
  const doIosCheck = () => {
    // console.log('doIosCheck', pwa);
    if (pwa.checked || isDismissed()) return;
    // detect if the device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent) && !('MSStream' in window);
    };
    // check if the device is in standalone mode
    const isInStandaloneMode = () => {
      return ("standalone" in window.navigator && window.navigator.standalone);
    };
    const showIt = isIos() && !isInStandaloneMode();
    setPwa({
      ...pwa,
      isIos: isIos(),
      installable: showIt,
      checked: true,
    });
  };

  useEffect(() => {

    doIosCheck();

    window.addEventListener("beforeinstallprompt", (e) => {
      // console.log('beforeinstallprompt Event fired', e);
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setPwa({
        ...pwa,
        installable: true,
      });
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      // console.log('INSTALL: Success');
      setPwa({
        ...pwa,
        installable: false,
      });
    });

  }, [pwa]);

  const handleInstallClick = (e) => {
    // Hide the app provided install promotion
    setPwa({
      ...pwa,
      installable: false,
    });
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        // console.log('User accepted the install prompt');
      } else {
        // console.log('User dismissed the install prompt');
      }
    });
  };

  const handleClose = () => setPwa({
    ...pwa,
    modal: false,
  });
  const handleIosClick = () => setPwa({
    ...pwa,
    modal: true,
  });
  const handleDismiss = () => {
    localStorage.setItem(lsKey, true);
    setPwa({
      ...pwa,
      modal: false,
      installable: false,
    });
  };

  return (
    <Container className="container-app">

      <Navbar fixed="bottom" id="nav-faves">
        {!!pwa.installable && (
        <Navbar.Collapse className="container justify-content-start">
          <Nav.Link onClick={pwa.isIos ? handleIosClick : handleInstallClick} className="pwa-install">
            Install
          </Nav.Link>
        </Navbar.Collapse>
        )}
        <Navbar.Collapse className="container justify-content-end">
          <Nav.Link href="/favourites" className="fav-button">
            ★
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={pwa.modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Install App</Modal.Title>
        </Modal.Header>
        <Modal.Body>To install the program app, tap the “share” icon <img src="/images/ios-share.svg" alt='iOS share icon' />, and then tap on “Add to Home Screen”.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDismiss}>
            Don't show again
          </Button>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <RouterProvider router={router}>
        <Outlet />
      </RouterProvider>
    </Container>
  );

};
