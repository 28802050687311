import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ScheduleList from "../components/ScheduleList";
import PageHeader from "../components/PageHeader";
import { useEffect, useState, useCallback, createRef } from 'react';

export default function NlSchedule() {

  var theDay = localStorage.getItem('lsScheduleDay') || 'friday';
  var sPos = localStorage.getItem('lsSchedulePos') || 0;

  const [state, setState] = useState({
    scrollPos: sPos,
    scheduleDay: theDay
  });

  useEffect(() => {
    // console.log('State change', state.scrollPos, window.scrollY);
    if (window.scrollY === 0 && state.scrollPos > 0) {
      // console.log('!! SCROLL POS NOT SET !!', state.scrollPos, window.scrollY);
      // Hopefully this is on initial load only.
      window.scrollTo(0, state.scrollPos);
      // console.log('Scroll Pos Set to: ', window.scrollY);
    } else if (window.scrollY > 0 && state.scrollPos === 0) {
      // console.log('?? SCROLL POS RESET ??', state.scrollPos, window.scrollY);
      // This should occur when the tab is clicked. See event below.
      window.scrollTo(0, state.scrollPos);
      // console.log('Scroll Pos Set to: ', window.scrollY);
    }
    // Update local storage on state change
    // console.log('Setting local storage', state.scheduleDay, state.scrollPos);
    localStorage.setItem('lsScheduleDay', state.scheduleDay);
    localStorage.setItem('lsSchedulePos', state.scrollPos);
    // Create the scroll listener.
    const onScroll = () => {
      const pos = window.scrollY;
      // console.log('onScroll: ' + pos);
      setState({ ...state, scrollPos: pos });
      // localStorage.setItem('lsSchedulePos', pos);
    };
    // Create the load listener
    const onLoad = () => {
      // Scroll to pos
      // console.log('Load - Setting ScrollPos', state.scrollPos);
      window.scrollTo(0, state.scrollPos);
    }
    // Add the listeners.
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    window.removeEventListener('load', onLoad);
    window.addEventListener('load', onLoad);
    return () => {
      // And remove them when departing.
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('load', onLoad);
    }
  }, [state]);

  const tabClick = useCallback((e) => {
    // console.log('Tab Click: ', e);
    setState({
      scrollPos: 0,
      scheduleDay: e,
    });
  }, [state]);

  return (
    <div className="nl-schedules mb-3">
      <PageHeader title='Set Times' />
      <Tabs
        defaultActiveKey={state.scheduleDay}
        id="schedule-tabs"
        onSelect={tabClick}
      >
        <Tab eventKey="friday" title="Fri">
          <ScheduleList useDay="friday" />
        </Tab>
        <Tab eventKey="saturday" title="Sat">
          <ScheduleList useDay="saturday" />
        </Tab>
        <Tab eventKey="sunday" title="Sun">
          <ScheduleList useDay="sunday" />
        </Tab>
        <Tab eventKey="monday" title="Mon">
          <ScheduleList useDay="monday" />
        </Tab>
      </Tabs>
    </div>
  );

}
