import {useCallback, useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {getLsFavourites, setLsFavourites, toggleFaveArtist} from "../json/favourites";
import {nlGetData} from "../App";
import {Card} from "react-bootstrap";

export default function ArtistList(props) {

  const nlData = nlGetData();

  // Faves state from local storage
  const [lsFaves2025, setFaves] = useState(getLsFavourites());

  // Update local storage on lsFaves2025 change
  useEffect(() => {
    setLsFavourites(lsFaves2025);
  }, [lsFaves2025]);

  // Favourites button click => toggle as fave
  const faveArtist = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    let slug = event.currentTarget.dataset.slug;
    // console.log('faveArtist', event.currentTarget, slug);
    setFaves({
      events: lsFaves2025.events,
      artists: toggleFaveArtist(lsFaves2025.artists, slug)
    });
  }, [lsFaves2025]);

  // Set up data list
  let showFavourites = props.showFavourites;
  let performers = nlData.performers;
  // console.log(performers);
  if (!!showFavourites) {
    performers = performers.filter((item) => {
      // console.log('Filter', item, lsFaves2025.artists.includes(item.slug));
      return lsFaves2025.artists.includes(item.slug);
    });
  }
  let searchTerm = props.searchTerm;
  if (!!searchTerm) {
    performers = performers.filter((item) => {
      // return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      let title = item.title.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase();
      return title.includes(searchTerm.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase());
    });
  }

  return (
    <div className='artist-list'>
        {
          (!!showFavourites && lsFaves2025.artists.length === 0) ? (
            <h6 className='mt-3'>Add some <a href='/performers'>performers</a> to your <span className='no-break'>favourites (☆)</span> and they'll be listed here.</h6>
          ) : (
            performers.map((performer) => {
              return (
                <div key={performer.uid}>

                  <div className='card'>
                    <div className='image'>
                      <Link to={'/performer/' + performer.slug}>
                        <Card.Img src={performer.image}/>
                      </Link>
                    </div>

                    <div className='title h5'>
                      <div className='performer-title col-11'>
                        <Link to={'/performer/' + performer.slug}>{performer.title}</Link>
                        { !!performer.byline && (<div className='performer-byline'>{performer.byline}</div>) }
                      </div>
                      <div className='p-fave col-1'>
                        <a
                          href={'/performer/' + performer.slug}
                          className='fav-button'
                          data-slug={ performer.slug }
                          onClick={ faveArtist }
                          title={ lsFaves2025.artists.includes(performer.slug) ? 'Remove Favourite' : 'Add Favourite' }
                        > { lsFaves2025.artists.includes(performer.slug) ? '★' : '☆'}</a>
                      </div>
                    </div>

                  </div>

                </div>
              ); // return
            }) // .map
          ) // if empty
        }
    </div>
  );

}
