import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getLsFavourites,
  setLsFavourites,
  toggleFaveEvent,
} from '../json/favourites';

import '../css/nl.scss';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { nlGetData } from '../App';
import { Button } from 'react-bootstrap';
import Map from './Map';
import { SvgClock, SvgMapMarker } from './svg';

export default function ScheduleList(props) {

  const nlData = nlGetData();

  let schedule = nlData.schedule;
  let performers = nlData.performers;
  let venues = nlData.venues;
  let events = [];

  // Faves state from local storage
  const [state, setState] = useState({
    faves: getLsFavourites(),
    venue: {
      title: '',
      description: '',
      slug: '',
    },
    modalOpen: false,
  });

  // Update local storage on state change
  useEffect(() => {
    setLsFavourites(state.faves);
  }, [state]);

  // Favourites button click => toggle as fave
  const faveEvent = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    let uid = event.currentTarget.dataset.uid;
    // console.log('faveEvent', event, uid);
    setState({
      ...state,
      faves: {
        ...state.faves,
        events: toggleFaveEvent(state.faves.events, uid),
      },
    });
  }, [state]);

  // Set up data list
  let showFavourites = props.showFavourites;
  // console.log('showFavourites', showFavourites);
  let useDay = props.useDay;
  let useVenue = props.useVenue;
  let usePerformer = props.usePerformer;
  // console.log('usePerformer', usePerformer, !!usePerformer);
  if (!!useDay) {
    events = schedule[useDay];
  } else {
    for (const [day] of Object.entries(schedule)) {
      events = events.concat(schedule[day]);
    }
  }
  if (!!useVenue) {
    // console.log('Using the venue', useVenue);
    events = events.filter((item) => {
      // console.log('slug', item.venue.slug);
      return item.venue.slug === useVenue;
    });
  }
  if (!!usePerformer) {
    events = events.filter((item) => {
      return item.artist.slug === usePerformer;
    });
  }
  if (!!showFavourites) {
    // console.log('Events', events);
    events = events.filter((item) => {
      // console.log('Filter', item, state.faves.events.includes(item.slug));
      return state.faves.events.includes(item.uid);
    });
  }

  // Modal functionality
  function showVenue(e) {
    e.stopPropagation();
    e.preventDefault();

    let slug = e.currentTarget.dataset.slug;
    // console.log('showVenue', slug);
    let venue = venues.find((item) => {
      return item.slug === slug;
    });
    // console.log(venue.title, venue.address.formatted);

    return venue && setState({
      ...state,
      modalOpen: true,
      venue: venue,
    });
  }

  function closeModal() {
    setState({
      ...state,
      modalOpen: false,
    });
  }

  // internal functions
  function ft(date) {
    // date.toLocaleString('en-AU', {year: 'numeric', month: '2-digit', day: '2-digit', weekday:"long", hour: 'numeric', hour12: true, minute:'2-digit', second:'2-digit'})
    return date.toLocaleString('en-AU',
      { hour: 'numeric', hour12: true, minute: '2-digit' });
  }

  function fd(date) {
    return date.toLocaleString('en-AU',
      { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' });
  }

  function dow(date) {
    return date.toLocaleString('en-AU', { weekday: 'long' });
  }

  function sdow(date) {
    return date.toLocaleString('en-AU', { weekday: 'short' });
  }

  function pl(slug) {
    return performers.find((item) => {
      return item.slug === slug;
    });
  }

  function getVL(slug) {
    let venue = venues.find((item) => {
      return item.slug === slug;
    });
    return venue;
  }

  let today = null;

  // console.log('Events', events);

  return (
    <div className="schedule-list">
      {
        (!!showFavourites && events.length === 0) ? (
          <h6 className="mt-3">Add some <a href="/schedule">set times</a> to
            your <span className="no-break">favourites (☆)</span> and they'll be
            listed here.</h6>
        ) : (
          // eslint-disable-next-line array-callback-return
          !!events && events.map((item, index) => {
            let start = new Date(item.start);
            let end = new Date(item.end);
            // console.log('Item', item);
            // console.log('Slug', item.venue.slug);
            let venue = !!item.venue.slug ? getVL(item.venue.slug) : null;
            let day = dow(start);
            // console.log(item, useVenue, venue);
            let header = null;
            if (!useDay && start.getDay() !== today) {
              today = start.getDay();
              header = fd(start);
            }
            if (!!usePerformer || !!showFavourites || !item.hidden) {
              return (
                <div key={'slot' + index}>
                  {!!header && (
                    <div id={day}>
                      <div className="date-header">{header}</div>
                    </div>
                  )} <Row>
                  <div className="details col-11">
                    {!usePerformer && (
                      <>
                        {!!pl(item.artist.slug) ? (
                          <div className="performer-title">
                            <Link to={'/performer/' + item.artist.slug}>{item.artist.title}</Link>
                          </div>
                        ) : (
                          <div className="performer-title">{item.artist.title}</div>
                        )
                        }
                      </>
                    )}
                    {!!item.extra && (
                      <div className="extra">{item.extra}</div>
                    )}
                    {!!item.workshop && (
                      <div className="workshop">Workshop</div>
                    )}
                    {!!item.made_and_played && (
                      <div className="workshop">Made & Played</div>
                    )}
                    <div className="time">
                      {SvgClock}
                      {!!props.showDay && (
                        <span className="short-day">{sdow(start)}</span>
                      )}
                      {ft(start)} - {ft(end)}
                    </div>
                    {!useVenue && (
                      <>
                        {!!venue ? (
                          <div className="venue-title">{SvgMapMarker}<a href={'/venue/' +
                            venue.slug}
                                                                        data-slug={venue.slug}
                                                                        onClick={showVenue}>{venue.title}</a>
                          </div>
                        ) : (
                          <div className="venue-title">{SvgMapMarker} {item.venue.title}</div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="fave col-1">
                    <a
                      href="#add-fav"
                      className="fav-button"
                      data-uid={item.uid}
                      onClick={faveEvent}
                      title={state.faves.events.includes(item.uid)
                        ? 'Remove Favourite'
                        : 'Add Favourite'}
                    > {state.faves.events.includes(item.uid) ? '★' : '☆'}</a>
                  </div>
                </Row>
                </div>
              ); // return
            } // if not hidden or is performer
          }) // events.map
        ) // if empty
      } {/* End of List */}
      <Modal className="modal-venue" size="lg" show={state.modalOpen} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>{state.venue.title}</Modal.Title> </Modal.Header>
        <Modal.Body> <Map venue={state.venue.slug} /> {!!state.venue.location &&
          (<p className="location">Located
            at {state.venue.location}</p>)} {!!state.venue.address &&
          !!state.venue.address.formatted &&
          (<div className="address">{state.venue.address.formatted}</div>)}<p>
          <Link to={'/venue/' + state.venue.slug}>More details</Link>
        </p>
        </Modal.Body> <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}> Close </Button>
      </Modal.Footer> </Modal>
    </div>
  );

}