import { Link, useLoaderData } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import ScheduleList from '../components/ScheduleList';
import PageHeader from '../components/PageHeader';
import Map from '../components/Map';

import { SvgMapMarker } from '../components/svg';

export default function NlVenues() {

  const { nlData } = useLoaderData();
  const [state, setState] = useState({
    setsOpen: false,
    mapOpen: false,
    venue: {
      title: '',
      description: '',
      slug: '',
    },
  });

  let venues = nlData.venues;
  let schedule = nlData.schedule;
  let events = [];
  for (const [day] of Object.entries(schedule)) {
    events = events.concat(schedule[day]);
  }

  function closeModal() {
    setState({
      ...state,
      setsOpen: false,
      mapOpen: false,
    });
  }

  function getV(slug) {
    return venues.find((item) => {
      return item.slug === slug;
    });
  }

  /*
    function showSetList(e) {
      e.stopPropagation();
      e.preventDefault();

      let slug = e.currentTarget.dataset.slug;
      let venue = getV(slug);

      return venue && setState({
        ...state,
        setsOpen: true,
        venue: venue
      });
    }

    function hasSlots(slug) {
      return events.find((item) => item.venue.slug == slug);
    }
  */

  function showOnMap(e) {
    e.stopPropagation();
    e.preventDefault();

    let slug = e.currentTarget.dataset.slug;
    // console.log('showOnMap', e.currentTarget, slug);
    let venue = getV(slug);
    // console.log('The Venue:', venue);

    return venue && setState({
      ...state,
      venue: venue,
      mapOpen: true,
    });
  }

  return (
    <div className="nl-venues mb-3">
      <PageHeader title="Venues" />
      <div className="mb-4">
        <Card>
          <Card.Header>
            <Card.Title className="venue-title">
              <div className="venue-number empty"></div>
              Festival Reception has moved!
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <p className="mb-1">Ticketing and performer merchandise is now at The White House next to The Country Store.</p>
            <p className="mb-1">Performer reception is in the Anglican Church hall across the road from The Troubadour.</p>
          </Card.Body>
        </Card>
      </div>
      {
        // eslint-disable-next-line array-callback-return
        venues.map((venue, index) => {
          if (!venue.unlisted) {
            return (
              <div key={index} className="mb-4">
                <Card> <Card.Header> <Card.Title className="venue-title">
                  <div className={!venue.number
                    ? 'venue-number empty'
                    : 'venue-number'}>{venue.number}</div>
                  {!!venue.slug
                    ? (<Link to={'/venue/' + venue.slug}>{venue.title}</Link>)
                    : venue.title}
                </Card.Title> </Card.Header> <Card.Body>
                  <p className="mb-1">{!!venue.short
                    ? venue.short
                    : venue.description}</p>
                </Card.Body> <Card.Footer>
                  {!!venue.location &&
                    (<p className="location">Located at {venue.location}</p>)}
                  <div className="address">
                    {!!venue.address && (
                      <a href={'/maps/' +
                        venue.slug} data-slug={venue.slug} onClick={showOnMap}>
                        {SvgMapMarker} {venue.address.formatted}
                      </a>
                    )}
                  </div>
                </Card.Footer> </Card>
              </div>
            );
          }
        })
      }
      <Modal id="modalSets" show={state.setsOpen} onHide={() => closeModal()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{state.venue.title}</Modal.Title> </Modal.Header>
        <Modal.Body> <ScheduleList useVenue={state.venue.slug} /> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}> Close </Button>
        </Modal.Footer> </Modal>
      <Modal id="modalMap" show={state.mapOpen} onHide={() => closeModal()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><span className="venue-number">{state.venue.number}</span> {state.venue.title}
          </Modal.Title> </Modal.Header> <Modal.Body>
        <Map venue={state.venue.slug} /> {/*
          <Card.Footer>
            { !!state.venue.location && (<p className='location'>Located at {state.venue.location}</p>) }
            <div className='address'>
              { state.venue.address && state.venue.address['formatted'] ? state.venue.address['formatted'] : '' }
              { state.venue.google && (<p className='gmap'><span className='mapMarker'>{SvgMapMarker}</span><a href="{state.venue.google}" target="_blank">Directions</a></p>) }
            </div>
          </Card.Footer>
          */}
      </Modal.Body> <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}> Close </Button>
      </Modal.Footer> </Modal>
    </div>
  );

}
