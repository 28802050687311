import {useLoaderData} from "react-router-dom";

import ScheduleList from "../components/ScheduleList";
import {nlGetData} from "../App";
import PageHeader from "../components/PageHeader";
import {Card} from "react-bootstrap";
import {useEffect} from "react";
import Map from "../components/Map";
import {SvgMapMarker} from "../components/svg";

export async function venueLoader({ params })
{
  // console.log(params);
  let nlData = nlGetData();
  let venues = nlData.venues;

  let venue = null;
  venues.map((item) => {
      if (item.slug === params.slug) {
          venue = item;
      }
      return venue;
  })

  return venue;
}

export default function NlVenuePage()
{
  const venue = useLoaderData();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
      <div className="nl-venue-page">
        <PageHeader title={venue.title} />
        <Card>
          <Card.Header>
            <Card.Title>
              <div className={!venue.number ? 'venue-number page empty' : 'venue-number page'}>{venue.number}</div>
              {venue.title}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <p>{venue.description}</p>
            <Map venue={venue.slug} />
          </Card.Body>
          <Card.Footer>
            { !!venue.location && (<p className='location'>Located at {venue.location}</p>) }
            <div className='address'>
              { venue.address.formatted }
              { venue.google && (<p className='gmap'><span className='mapMarker'>{SvgMapMarker}</span><a href={venue.google} target="_blank" rel="noreferrer">Directions</a></p>) }
            </div>
          </Card.Footer>
        </Card>
        <ScheduleList useVenue={venue.slug} />
      </div>
  );

}
