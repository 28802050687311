
import React from "react";
import BreakoutBox from "../components/BreakoutBox";
import PageHeader from "../components/PageHeader";
import {SvgFaq} from "../components/svg";

export default function NlFaq() {

  return (
    <div className='nl-faq'>
      <PageHeader title='Useful Info' />

      <BreakoutBox
        svg={SvgFaq}
        title='Useful Info'
        contents={
          <div>
            {
              /*
              <h4>The Community Centre</h4>
              <p>Festival reception, merchandise sales, music venue and café. Reception hours: Friday 1pm - 9pm, Sat & Sun 9am- 8pm, Mon 9am-1pm</p>
              */
            }
            <h4>Newstead Rural Transaction Centre (RTC)</h4>
            <p>Op Shop, tourist info, Internet access and Annual Secondhand Book Sale. Air-conditioned. Located near the Enviroshop so it’s on the shuttle bus route. Open Sat & Sun 9am-4pm.</p>

            <h4>Fuel</h4>
            <p>There is no fuel in Newstead. There is an electric vehicle charging station at The Enviroshop. Petrol is available in Maldon and Castlemaine. Closest gas is Castlemaine.</p>

            <h4>ATM</h4>
            <p>There is <i>no ATM in Newstead</i>. All businesses in our small town take cards but please note small businesses do not have the capacity to give cash out. Please bring cash if you need it.</p>

            <h4>No Fires</h4>
            <p>Please don’t light fires on the camping grounds or elsewhere, because (1) we are in a Fire Restriction period, (2) we would never be allowed to stage the festival again.</p>

            <h4>Accessibility Information</h4>
            <p>The Newstead Community Centre, The Troubadour and Lilliput are accessible venues, including disabled toilets. Enviroshop is accessible. The Crown Hotel, the Country Store and the Bakery / Supermarket all have portable ramps available. Dig Café has a ramp at the side entrance.</p>
            <p>If you are confronted by a step at any venue, ask someone to track down the portable ramp.</p>

            <h4>Shuttle Bus</h4>
            <p>Shuttle bus runs between the campground and the venues in the township. You can buy a weekend pass for $5 or pay a gold coin donation per trip.</p>

            <h4>No BYO in the Venues</h4>
            <p>Please don’t BYO drinks and food in to the festival venues. The Troubadour, Dig Cafe, Butterland and The Community Centre have alcoholic and non-alcoholic drinks available, and there is plenty of food available in or near venues. The sale of these items is an important part of revenue for the suppliers, which include the Newstead Football Netball Club, The Men’s Shed, and local businesses. Of course, you can BYO in the camping areas, but the township will be pleased if we attract a happy, peaceful and well-behaved crowd who don’t walk the streets drinking and carousing! So do consider the local amenity, and the future of this event.</p>

            <h4>Water</h4>
            <p>There are two water stations where you can refill your water bottles. One is outside the Community Centre and the other is at the entrance to The Troubadour.</p>

            <h4>In Case of Emergency</h4>
            <p>Notify the venue manager or MC if you need assistance.</p>
            <p><span className='fw-bold'>First Aid:</span> Medic is situated in the foyer of the community centre, with emergency response vehicle parked outside.</p>
            <p className='fw-bold'>Nurse on Call 1300 60 60 24. <br/>If the situation is more serious, DO NOT HESITATE TO DIAL 000</p>
          </div>
        }
      />
    </div>
  );

}