
export function getLsFavourites()
{
  // testing
/*
  return {
    artists: [],
    events: [],
  };
*/
  // real
  return JSON.parse(localStorage.getItem('lsFaves2025')) || {
    artists: [],
    events: [],
  };
}

export function setLsFavourites(lsFaves2025)
{
  // console.log('setfaves', JSON.stringify(lsFaves2025));
  localStorage.setItem('lsFaves2025', JSON.stringify(lsFaves2025));
}

export function toggleFaveArtist(artists, slug)
{
  if (artists.includes(slug)) {
    artists = artists.filter((item) => item !== slug );
  } else {
    artists.push(slug);
  }
  return artists;
}

export function toggleFaveEvent(events, uid)
{
  // console.log('toggleFaveEvent', events, uid);
  if (events.includes(uid)) {
    events = events.filter((item) => item !== uid );
  } else {
    events.push(uid);
  }
  return events;
}
